import React from 'react';

import Layout from '../components/Layout';
import { Waypoint } from 'react-waypoint';
import HeroImage from '../components/HeroImage';
import pic1 from '../assets/images/dew.png';
import pic2 from '../assets/images/cap.png';
import pic3 from '../assets/images/01.jpg';
import hero from '../assets/images/hero-background.webp';



import { Link } from 'gatsby';

class IndexPage extends React.Component {
constructor(props) {
  super(props)
  this.state = {
    stickyNav: false,
  }
}

_handleWaypointEnter = () => {
  this.setState(() => ({ stickyNav: false }))
}

_handleWaypointLeave = () => {
  this.setState(() => ({ stickyNav: true }))
}

render() {
  return (
  <Layout>

    <Waypoint
      onEnter={this._handleWaypointEnter}
      onLeave={this._handleWaypointLeave}
    ></Waypoint>

    <div id="main">
      <HeroImage
        imageSrc={hero}
        color="#555555"
        gradientDirection="to bottom right"
        height="100vh"
        opacity="0.8"
        childrenStyles={{ color: '#f7f7f7' }}
        parallax
        textPosition="center">
        <h1><div className="inner"><h5><p>Business Consulting with limitless potential </p></h5></div></h1>
        <div id="home" className="inner" >
          <Link to="/about" className="button">
            Learn More
          </Link>
        </div>
      </HeroImage> <br/>
      <div className="inner">
       <div id="about-us" className="box">
          <header className="major">
            <h2>ABOUT US</h2>
          </header>
          
          <article className="style1">
              <p align="left">
              From enterprise applications and web development to consulting and managed cloud services,our experts offer the best advice.
              </p>
            <div className="inner">
              <Link to="/about" className="button">
                  Learn more about us
              </Link>
            </div>
          </article>
          
        </div>
      <div id="services" className="box">
        <header className="major">
          <h2>SERVICES</h2>
        </header>
        <section id="services" className="tiles">
        
        <article className="box">
          <div className="inner" align="center">
            <span className="icon major style2 fa-cubes"></span>
            <h3>IT Consulting</h3>
            <p align="justify">
            Svatva together with our technology and consulting partners can guide you through all the 
            complexities of integrating, customizing, hosting and managing of complex applications. 
            Our proven methodology consists of various project phases, which ensures the success of enterprise-wide implementations.
            </p>
          </div>
          <div className="inner">
            <Link to="/services/ITConsulting" className="button">
            Explore
            </Link>
          </div>
        </article>

        <article className="box">
          <div className="inner" align="center">
            <span className="icon major style2 fa-users"></span>
            <h3>IT Support Services</h3>
            <p align="justify">
            Our development team provides expert web application development and 
            web design services to our clients.Svatva offers a variety of website 
            design and development services, from creating mobile web development 
            solutions and responsive website designs, to building custom e-commerce 
            and intranet experiences using the latest and proven web technologies.
            </p>
          </div>
          <div >
            <Link to="/services/ITSupportServices" className="button">
                Explore
            </Link>
          </div>
        </article>

        <article className="box" >
          <div className="inner" align="center"> 
            <span className="icon style2 fa-pencil-square"></span>
            <h3>Web Design</h3>
            <p align="justify">
            We’re a global brand and innovation consultancy. We work for the disruptors 
            and the disrupted, helping ambitious leaders define compelling brand strategies, 
            design powerful brand experiences and innovate new brand-led products and services. 
            We challenge ourselves and our clients to push boundaries; challenging the ordinary, 
            to achieve the extraordinary.
            </p>
          </div>
          <div className="inner">
            <Link to="/services/WebDesign"  className="button">
                Explore
            </Link>
          </div>

        </article>

        <article className="box">
          <div className="inner" align="center">
            <span className="icon major style2 fa-institution"></span>
            <h3>Software Training</h3>
            <p align="justify">
            Svatva has always believed in pragmatic skills for today’s enterprise, 
            we have delivered more than 1000 hours of corporate and individual IT training. 
            We have helped in shaping career of hundreds of professionals.
            </p>
          </div>
          <div className="inner">
            <Link to="/services/SoftwareTrng"  className="button">
              Explore
            </Link>
          </div>

        </article>

        <article className="box">
          <div className="inner" align="center">
            <span className="icon major style2 fa-line-chart"></span>
            <h3>SEO Services</h3>
            <p align="justify">
            Svatva  is highly experienced in both Search Engine Placement strategies 
            and Internet Marketing. When we launch a natural search engine optimization 
            campaign, we analyze how the engines view your website. That means we look at 
            your site's code, links and any graphics.  
            </p>
          </div>
          <div className="inner">
            <Link to="/services/SEOOptimization" className="button">
              Explore
            </Link>
          </div>

        </article>

        <article className="box">
          <div className="inner" align="center">
            <span className="icon major style2 fa-cloud"></span>
            <h3>Cloud Services</h3>
            <p align="justify">
            Amazon Web Services offers a broad set of global compute, storage; 
            database, analytics, application, and deployment services that help 
            organizations move faster or scale applications at low IT costs. 
            </p>
          </div>
          <div className="inner">
            <Link to="/services/CloudServices" className="button">
              Explore
            </Link>
          </div>
        </article>

        </section>

      </div>

      <div id="services" className="box">
        <header className="major">
          <h2>Our story with numbers</h2>
        </header>
        <section id="services" className="icons" align="justify">
        <ul className="icons">
          <li className="style2">
            <span className="icon style2 fa-map-marker"></span>
            <strong>3</strong> Locations
          </li>
          <li className="style2">
            <span className="icon style2 fa-users"></span>
            <strong>4</strong> Trainers
          </li>
          <li className="style2">
            <span className="icon style2 fa-list-ol"></span>
            <strong>6</strong>  Programs
          </li>
          <li className="style2">
            <span className="icon style2 fa-laptop"></span>
            <strong>1000+</strong> Training Hours
          </li>
          <li className="style2">
            <span className="icon style2 fa-diamond"></span>
            <strong>100+</strong> Students
          </li>
        </ul>
        </section>
      </div>

      {/*<div id="clients" className="box">
      <header className="major">
        <h2>Our Clients</h2><br />
        <p align="left">
          Svatva has worked with a large number of small and midsize customers from across the globe, across industry verticals and for varied solution offerings
        </p>
      </header>
      <section className="tiles">
          <article className="style1">
            <span className="image inner">
              <img src={pic1} alt="" />
            </span>
            <a href="https://www.dew.sc.gov/" >
            <h2>South Carolina Department of Employment and Workforce</h2>
              <div className="content">
                <p>
                  Unemployment insurance modernization project.
                </p>
              </div>
              </a>
          </article>
          <article className="style2">
            <span className="image">
              <img src={pic2} alt="" />
            </span>
            <a href="https://www.capgemini.com/us-en/service/capgemini-government-solutions/">
              <h2>Cap Gemini government solutions LLC</h2>
              <div className="content">
                <p>
                  Unemployment insurance product re-architecture. 
                </p>
              </div>
            </a>
          </article>
          <article className="style3">
            <span className="image">
              <img src={pic3} alt="" />
            </span>
            <a href="https://www.lucidittraining.com/">
              <h2>Lucid Training Consultancy</h2>
              <div className="content">
                <p>
                  Ousourced IT projects
                </p>
              </div>
            </a>
          </article>
      </section>
     </div>

     <div id="testimonials" className="box" >
        <section id="testimonials" className="inner" >
        <div className="inner" align="left">
          <header className="major">
        <h2>What our clients say about us </h2><br />
          <p align="left">
            Svatva has worked with a large number of small and midsize customers from across the globe, across industry verticals and for varied solution offerings
            <br />
          </p>
        </header>
        
          <h3>Blockquote</h3>
					<blockquote>Fringilla nisl. Donec accumsan interdum nisi, quis tincidunt felis sagittis eget tempus euismod. 
            Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat 
            ac adipiscing accumsan faucibus. Vestibulum ante ipsum primis in faucibus lorem ipsum dolor 
            sit amet nullam adipiscing eu felis.
          </blockquote>
          <br/>
          <h3>Blockquote</h3>
					<blockquote>Fringilla nisl. Donec accumsan interdum nisi, quis tincidunt felis sagittis eget tempus euismod. 
            Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat 
            ac adipiscing accumsan faucibus. Vestibulum ante ipsum primis in faucibus lorem ipsum dolor 
            sit amet nullam adipiscing eu felis.
          </blockquote>
          <br/>
          <h3>Blockquote</h3>
					<blockquote>Fringilla nisl. Donec accumsan interdum nisi, quis tincidunt felis sagittis eget tempus euismod. 
            Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat 
            ac adipiscing accumsan faucibus. Vestibulum ante ipsum primis in faucibus lorem ipsum dolor 
            sit amet nullam adipiscing eu felis.
          </blockquote>
          </div>
        </section>
      </div> */}

        <div id="home" className="box" >
          <section className="main special" align="center">
            <ul className="actions" align="center">
                <li>
                <Link to="/" className="button">
                <font color="red" weight="bold">Home</font>
                </Link>
                </li>
            </ul>
          </section>
        </div>
      </div>

    </div>
  </Layout>
    )
  }
}

export default IndexPage;
